<template>
	<div class="buysuccess">
		<div style="height: 30%;">&nbsp;</div>
		<div class="flexcenter">
			<img src="../../public/static/images/success.png" width="44" height="48"/>
		</div>
		<div class="success">订单提交成功</div>
		<div style="display: flex;">
			<div style="flex: 1;"></div>
			<div class="return" @click="returnIndex">返回首页</div>
			<div style="flex: 1;"></div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		methods: {
			returnIndex() {
              this.$router.push('/home');
			}
		}
	}
</script>

<style>
	.buysuccess {
		height: 100%;
		width: 100%;
		text-align: center;
	}
	
	.success {
		margin-top: 15px;
		font-size: 18px;
		color: #6a6eac;
		text-align: center;
	}
	
	.return {
		margin-top: 20px;
		background: #26a1ff;
		color: #FFFFFF;
		width: 93px;
		text-align: center;
		height: 32px;
		line-height: 32px;
		border-radius: 5px;
	}
</style>